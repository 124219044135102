import {Coin, serviceExchangeApi} from "@coma/api/exchanges";
import {CalculateOutlined} from "@mui/icons-material";
import React, {useCallback, useEffect, useState} from "react";

import {
    Error,
    getCoinOptions,
    Header,
    IconButton,
    IconButtonSize,
    ProgressIndicator,
    Select,
    TextField,
    TextFieldPropsType
} from "../../components";
import useServiceApi from "../../hooks/useServiceApi";
import './adapted.price.css';
import {AdaptedPriceProps} from "./adapted.price.props";
import {AdaptedValueDto} from "@coma/api/exchanges/dto/adapted.value.dto";

const DEFAULT_PRICE = 0.02;
const DEFAULT_QUANTITY = 30000;
const DEFAULT_COIN = Coin.DEFI;

/**
 * Adapted price module
 */
export const AdaptedPrice = function AdaptedPriceModule(props: AdaptedPriceProps) {
    const { onBack, onLogout } = props;
    const [ price, setPrice ] = useState<number>(DEFAULT_PRICE);
    const [ quantity, setQuantity ] = useState<number>(DEFAULT_QUANTITY);
    const [ coin, setCoin ] = useState<Coin>(DEFAULT_COIN);
    const [ adaptedValue, setAdaptedValue ] = useState<AdaptedValueDto>({ adaptedValue: 0, adaptedPrice: 0});
    const {
        execute: executeCalculateAdaptedValue,
        waiting: waitingCalculateAdaptedValue,
        error: errorCalculateAdaptedValue
    } = useServiceApi<AdaptedValueDto>(serviceExchangeApi.calculateAdaptedValue);

    const calculateAdaptedValue = useCallback((price: number, quantity: number, coin: Coin) => {
        executeCalculateAdaptedValue({price, quantity, coin})
            .then((value) => {
                setAdaptedValue(value);
            })
    }, [ executeCalculateAdaptedValue ]);

    useEffect(() => {
        calculateAdaptedValue(DEFAULT_PRICE, DEFAULT_QUANTITY, DEFAULT_COIN);
    }, [calculateAdaptedValue]);

    return <div className={"adapted_price"}>
        {
            waitingCalculateAdaptedValue ?
                <ProgressIndicator/>
                :
                errorCalculateAdaptedValue ?
                    <Error errorTx={errorCalculateAdaptedValue} tryAgainAction={() => calculateAdaptedValue(price, quantity, coin)} onLogout={onLogout}/>
                    :
                    <>
                        <Header title="Settings" onBack={onBack}/>
                        <div className={"adapted_price__title"}>
                            Calculated adapted price
                        </div>
                        <Select labelTx={"Coin"}
                                value={coin}
                                options={getCoinOptions()}
                                onChange={(newValue: string) => {
                                    setCoin(newValue as Coin)
                                }}
                        />
                        <div className={"content__devider"}/>
                        <TextField labelTx={"Price"}
                                   type={TextFieldPropsType.number}
                                   value={price}
                                   onChange={(newValue) => {
                                       setPrice(Number.parseFloat(newValue))
                                   }}
                        />
                        <TextField labelTx={"Quantity"}
                                   type={TextFieldPropsType.number}
                                   value={quantity}
                                   onChange={(newValue) => {
                                       setQuantity(Number.parseFloat(newValue))
                                   }}
                        />
                        <div className={"adapted_price__calculate-button"}>
                            <IconButton icon={CalculateOutlined}
                                        size={IconButtonSize.LARGE}
                                        onClick={() => calculateAdaptedValue(price, quantity, coin)}
                            />
                        </div>
                        <div className={"adapted_price__price"}>
                            Adapted price: {adaptedValue.adaptedPrice}
                        </div>
                        <div className={"adapted_price__price"}>
                            Adapted value: {adaptedValue.adaptedValue}
                        </div>
                    </>
        }
    </div>
}
