import {AxiosRequestConfig} from "axios";
import {axios_get, axios_post} from "../../common";
import {Coin} from "../dto/coin";
import {ExchangePricesDto} from "../dto/exchange.prices.dto";
import {AdaptedValueDto} from "../dto/adapted.value.dto";

export const EXCHANGES_PRICES_ROUTE = "/exchanges-prices"

export const EXCHANGES_PRICES_ADAPTED_VALUE = "/exchanges-prices-adapted-value"

class ExchangeApi {

    getExchangesPrices(): Promise<ExchangePricesDto[]> {
        const config: AxiosRequestConfig = {};
        return axios_get(EXCHANGES_PRICES_ROUTE, config);
    }

    calculateAdaptedValue(params: {price: number, quantity: number, coin: Coin}): Promise<AdaptedValueDto> {
        const config: AxiosRequestConfig = {};
        return axios_post(EXCHANGES_PRICES_ADAPTED_VALUE, params, config);
    }
}

export const serviceExchangeApi = new ExchangeApi();
